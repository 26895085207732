<template>
  <div class="main-app-container">
    <div class="primary-app-content">
      <app-header />
      <div class="main-app-content">
        <app-admin-sidebar />
        <main class="admin-base-container-padding">
          <slot />
        </main>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<style scoped>
.primary-app-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 80%;
}

.main-app-content {
  display: flex;
  height: 100%;
}

.main-app-content main {
  flex: 1 1 auto;
}
</style>
