<template>
  <aside class="admin-sidebar">
    <nav>
      <ul class="reset-list">
        <li>
          <nuxt-link to="/admin/target-words/manage"
            >Manage target words</nuxt-link
          >
        </li>
        <li>
          <nuxt-link to="/admin/target-words/create"
            >Add target words</nuxt-link
          >
        </li>
        <li>
          <nuxt-link to="/admin/blend-directions/create"
            >Blend directions</nuxt-link
          >
        </li>
        <li>
          <nuxt-link to="/admin/phonemes/create">Phonemes</nuxt-link>
        </li>
        <li>
          <nuxt-link to="/admin/positions/create">Positions</nuxt-link>
        </li>
        <li>
          <nuxt-link to="/admin/roles/create">User roles</nuxt-link>
        </li>
        <li>
          <nuxt-link to="/admin/target-sounds/create">Target sounds</nuxt-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<style lang="css" scoped>
.admin-sidebar {
  background-color: var(--color-blue-05);
  flex: 0 0 20rem;
  padding: var(--layout-vertical-base-padding)
    var(--layout-horizontal-base-padding);
}

.admin-sidebar ul {
  display: flex;
  flex-direction: column;
  gap: var(--layout-vertical-base-padding);
}
</style>
